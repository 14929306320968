import { css } from '@emotion/react';

export const control = css`
    display: flex;
    position: relative;

    & > div:nth-of-type(1) {
        background: #ffb300;
        color: white;
        font-weight: 600;
        font-size: 0.9rem;
        text-transform: uppercase;
        padding: 0.5rem 1rem;
        border-top-left-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem;
    }

    & > div:nth-of-type(2) {
        flex: 1 1 100%;

        select,
        input {
            display: block;
            width: 100%;
            height: 100%;
            border: 2px solid #ffb300;
            border-top-right-radius: 0.2rem;
            border-bottom-right-radius: 0.2rem;
            padding: 0 2rem 0 1rem;
            font-weight: 600;
            text-transform: uppercase;
        }

        input {
            padding: 0 2.5rem 0 1rem;
        }

        option {
            color: #2d2d2d;
            font-weight: 600;
        }
    }

    button {
        box-sizing: border-box;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 2rem;
        background: white;
        border-top-right-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
        background: white;
        color: #ffb300;
        border: 2px solid #ffb300;
        padding: 0.2rem;
        cursor: pointer;
    }
`;
