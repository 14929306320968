import { css } from '@emotion/react';

export const itemListing = css`
    color: #2d2d2d;
    text-decoration: none;
    border-radius: 0.3rem;
    text-align: right;
    position: relative;
    display: block;
    overflow: hidden;
`;

export const itemName = css`
    margin-top: 1rem;

    &:hover {
        text-decoration: underline;
    }
`;

export const categoryTags = css`
    div {
        text-transform: uppercase;
        font-size: 0.8rem;
        color: #999;
    }
`;

export const priceWrapper = css`
    margin-top: 0.5rem;
    font-size: 2.4rem;
    color: #ffb300;
    font-weight: 500;
    /* border-top: 2px solid rgba(255, 255, 255, 0.3); */
`;

export const callout = css`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: white;
    background: #ffb300;
    z-index: 2;
    text-align: center;
    font-weight: 600;
    font-size: 0.9rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
`;
