import React, { useMemo } from 'react';

import Icon from '@components/Icon';

import * as s from './styles';

type Props = {
    label: string;
    value: string;
    options: Array<{
        name: string;
        value: string;
    }>;
    onChange: React.ChangeEventHandler<HTMLSelectElement>;
};

const Select: React.FC<Props> = ({ label, onChange, value, options }) => {
    const id = useMemo(() => Math.ceil(Math.random() * 10 ** 20).toString(), []);

    return (
        <label css={s.control} htmlFor={id}>
            <div>{label}</div>
            <div>
                <select id={id} name={label} onChange={onChange} value={value}>
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.name}
                        </option>
                    ))}
                </select>
            </div>
            <button type="button" style={{ pointerEvents: 'none' }}>
                <Icon name="carret-down" />
            </button>
        </label>
    );
};

export default Select;
