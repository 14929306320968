import { css } from '@emotion/react';

export const cartButton = css`
    border: none;
    outline: none;
    background: transparent;

    &:hover {
        cursor: pointer;
    }
`;

export const cartIcon = css`
    position: relative;
    top: 0.3rem;
    width: 2rem;

    &:hover span {
        transform: scale(1.2);
    }

    span {
        position: absolute;
        color: #2d2d2d;
        font-weight: 600;
        font-size: 0.9rem;
        top: -0.5rem;
        right: -0.5rem;
        z-index: 1;
        transition: transform 0.3s;
        transform: scale(1);

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            background: #ffb300;
            z-index: -1;
        }
    }

    svg {
        fill: #ffffff;
        width: 100%;
    }
`;

export const cart = css`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 100%;
    width: calc(100%);
    max-width: 28rem;
    height: 100%;
    background: #fff;
    z-index: 20;

    /* Extra "padding" for the slide in animation */
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        width: 5rem;
        height: 100%;
        background: white;
    }
`;

export const header = css`
    flex: 0 0 4rem;
    display: flex;
    padding: 0 1rem;
    justify-content: space-between;
    align-items: center;
`;

export const title = css`
    font-size: 1.6rem;
`;

export const close = css`
    flex: 0 0 2rem;
    background: transparent;
    padding: 0.5rem;
    border: none;

    &:hover {
        cursor: pointer;
    }
`;

export const cartWrapper = css`
    flex: 1 1 100%;
    display: flex;
    padding: 1rem;
`;

export const footer = css`
    flex: 0 0 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem 1rem 1rem;
`;

export const buttonText = css`
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
`;

export const overlay = css`
    background: none;
    border: none;
    outline: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
`;
