import React from 'react';

import * as s from './styles';

type Props = {
    value: number;
};

const Price: React.FC<Props> = ({ value }) => {
    const centsValue = value % 100;
    const dollarsValue = (value - centsValue) / 100;

    return (
        <span>
            <span css={s.price}>
                <span css={s.sign}>$</span>
                <span css={s.dollars}>{dollarsValue}</span>
                <span css={s.cents}>{centsValue.toString().padEnd(2, '0')}</span>
            </span>
        </span>
    );
};

export default Price;
