import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { format, set, isBefore, isThisWeek, isSameDay, addDays, isToday } from 'date-fns';

import locationsMeta from '@config/locations.config';
import { MerchantLocation, MerchantsQuery, Weekday } from '@lib/types';

const setDateTime = (date: Date, time: number) => {
    const minutes = time % 100;
    const hours = (time - minutes) / 100;

    return set(date, {
        hours,
        minutes,
        seconds: 0,
        milliseconds: 0,
    });
};

const useLocations = () => {
    const { allCloverMerchant } = useStaticQuery<MerchantsQuery>(graphql`
        query Merchants {
            allCloverMerchant {
                nodes {
                    address {
                        address1
                        city
                        phoneNumber
                        state
                        zip
                    }
                    openingHours {
                        elements {
                            friday {
                                elements {
                                    end
                                    start
                                }
                            }
                            monday {
                                elements {
                                    end
                                    start
                                }
                            }
                            saturday {
                                elements {
                                    end
                                    start
                                }
                            }
                            sunday {
                                elements {
                                    end
                                    start
                                }
                            }
                            thursday {
                                elements {
                                    end
                                    start
                                }
                            }
                            tuesday {
                                elements {
                                    start
                                    end
                                }
                            }
                            wednesday {
                                elements {
                                    end
                                    start
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    return useMemo(() => {
        const currentDate = new Date();

        return allCloverMerchant.nodes.reduce<MerchantLocation[]>((acc, { address, openingHours }) => {
            const todaysWeekday = format(currentDate, 'EEEE').toLowerCase() as Weekday;
            const todaysHours = openingHours.elements[0][todaysWeekday].elements[0];
            const todaysStartDate = setDateTime(currentDate, todaysHours.start);
            const todaysEndDate = setDateTime(currentDate, todaysHours.end);

            const meta = locationsMeta[address.city.toLowerCase()];

            if (!meta) {
                return acc;
            }

            const closings = meta.closings.reduce<Date[]>((accumulator, date) => {
                const [month, day, year] = date.split('/').map(Number);
                const closingDate = new Date(year, month - 1, day);
                return isThisWeek(closingDate) ? [...accumulator, closingDate] : accumulator;
            }, []);

            const isOpen =
                isBefore(todaysStartDate, currentDate) &&
                isBefore(currentDate, todaysEndDate) &&
                !closings?.some((date) => isSameDay(currentDate, date));

            const tableDays = Array.from({ length: 7 }).map((_, i) => {
                const date = addDays(currentDate, -currentDate.getDay() + i);
                const weekday = format(date, 'EEEE').toLowerCase() as Weekday;
                const hours = openingHours.elements[0][weekday].elements[0];
                const startDate = setDateTime(date, hours.start);
                const endDate = setDateTime(date, hours.end);
                const isClosed =
                    typeof closings === 'undefined' || closings.some((closingDate) => isSameDay(closingDate, date));

                return {
                    weekday,
                    hours: isClosed ? 'closed' : `${format(startDate, 'h:mm a')} - ${format(endDate, 'h:mm a')}`,
                    today: isToday(date),
                    closed: isClosed,
                };
            });

            return [
                ...acc,
                {
                    ...address,
                    ...meta,
                    isOpen,
                    schedule: tableDays,
                },
            ];
        }, []);
    }, []);
};

export default useLocations;
