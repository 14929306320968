import React, { useState, useMemo } from 'react';
import fetch from 'unfetch';

import { useCart } from '@hooks';
import Button from '../Button';
import * as s from './styles';

type PromotionData =
    | {
          valid: false;
      }
    | {
          valid: true;
          type: 'amount' | 'percentage';
          value: number;
          threshold: number;
      };

interface StatusData {
    text: string;
    color?: string;
}

const Status: Record<string, StatusData> = {
    DEFAULT: { text: 'Apply Code' },
    LOADING: { text: '__LOADING__' },
    SUCCESS: { text: 'Code Applied', color: '#388e3c' },
    ERROR: { text: 'Invalid Code', color: '#ad2c2c' },
};

const PromoCodeForm = () => {
    const { promotions, addPromotion } = useCart();
    const prefix = useMemo(() => Math.ceil(Math.random() * 10 ** 20), []);
    const [code, setCode] = useState('');
    const [status, setStatus] = useState<StatusData>(Status.DEFAULT);

    const handleApplyCode = async (event: React.FormEvent) => {
        event.preventDefault();
        if (code === '') {
            return;
        }
        // Prevent reapplying same code multiple times
        if (promotions.some((promo) => promo.code === code)) {
            setCode('');
            return;
        }

        if (status.text === Status.ERROR.text) {
            return;
        }

        setStatus(Status.LOADING);

        const data: PromotionData = await fetch(`${process.env.API_PROMOTIONS}/${code}`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .catch(() => ({ valid: false }));

        if (!data.valid) {
            setStatus(Status.ERROR);
            return;
        }

        addPromotion({
            code,
            type: data.type,
            value: data.value,
            threshold: data.threshold,
        });
        setStatus(Status.SUCCESS);
        setCode('');
    };

    return (
        <form onSubmit={handleApplyCode}>
            <label css={s.label} htmlFor={`${prefix}--promoCode`}>
                <input
                    type="text"
                    id={`${prefix}--promoCode`}
                    name="promoCode"
                    placeholder="PROMOCODE"
                    onChange={(event) => {
                        setCode(event.target.value.trim().toUpperCase());
                        setStatus(Status.DEFAULT);
                    }}
                    value={code}
                />
            </label>
            <div css={s.buttonWrapper}>
                <Button type="submit" size="sm" color={status.color}>
                    {status.text}
                </Button>
            </div>
        </form>
    );
};

export default PromoCodeForm;
