import React from 'react';

import Icon from '@components/Icon';

import * as s from './styles';

const Alert: React.FC = ({ children }) => (
    <div css={s.alert}>
        <div css={s.container}>
            <div>
                <Icon name="warning" />
            </div>
            <div>{children}</div>
        </div>
    </div>
);

export default Alert;
