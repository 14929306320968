import { css } from '@emotion/react';

export const cartItem = css`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const imageWrapper = css`
    flex: 0 0 20%;
    max-width: 100%;
    border-radius: 0.5rem;
    overflow: hidden;
    margin-right: 1rem;
`;

export const image = css`
    width: 100%;
    display: block;
`;

export const content = css`
    flex: 1 1 100%;
`;

export const col3 = css`
    text-align: right;
    font-weight: 500;
    color: #bbb;
    font-size: 0.9rem;
    padding-left: 2rem;
    white-space: nowrap;
`;

export const title = css`
    color: #2d2d2d;
    text-decoration: none;
`;

export const counter = css`
    display: inline-block;
`;

export const itemTotal = css`
    color: #2d2d2d;
    font-weight: 600;
    font-size: 1.4rem;
`;
