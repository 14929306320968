import { css } from '@emotion/react';

export const price = css`
    span {
        line-height: 1em;
    }
`;

export const sign = css`
    font-size: 0.5em;
    position: relative;
    top: -0.6em;
`;

export const dollars = css``;

export const cents = css`
    font-size: 0.5em;
    position: relative;
    top: -0.7em;
`;
