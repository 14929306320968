import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import locationsMeta from '@config/locations.config';
import { formatPhone, cleanPhone } from '@lib';

import * as s from './styles';

type Props = {
    location: {
        address1: string;
        city: string;
        state: string;
        zip: string;
        phoneNumber: string;
    };
};

const Circle: React.FC<Props> = ({ location: { address1, city, state, zip, phoneNumber } }) => (
    <div css={s.circle}>
        <div css={s.content}>
            <h2 css={s.h2}>{city}</h2>
            <OutboundLink
                css={s.address}
                href={locationsMeta[city.toLowerCase()]?.link}
                target="_blank"
                rel="noopener noreferrer"
            >
                <address>
                    {address1}
                    <br />
                    {city} {state} {zip}
                </address>
            </OutboundLink>
            <OutboundLink css={s.tel} href={`tel:+1${cleanPhone(phoneNumber)}`}>
                {formatPhone(phoneNumber)}
            </OutboundLink>
        </div>
    </div>
);

export default Circle;
