import { css } from '@emotion/react';

export const title = css`
    text-align: left;

    &.closed::after {
        content: '(closed)';
        display: inline-block;
        margin-left: 0.5rem;
        color: #ad2c2c;
        font-weight: 900;
        font-size: 1rem;
        position: relative;
        top: -0.2rem;
    }
`;

export const map = css`
    display: block;
    width: 100%;
    padding-top: 70%;
    position: relative;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 6px solid #ffb300;
        border-radius: 0.3rem;
    }
`;

export const table = css`
    border-radius: 0.3rem;
    overflow: hidden;
`;

export const row = css`
    display: flex;

    &.today {
        & > div {
            background: #ffb300;
            font-weight: 900;
            color: white;
        }

        &.closed {
            & > div {
                background: #bbb !important;
                color: #444;
            }
        }
    }

    &.closed {
        & > div {
            color: #555;

            &:nth-of-type(1) {
                background: #ddd;
            }
        }
    }
`;

export const column = css`
    &:nth-of-type(1) {
        flex: 1 0 8.5rem;
        background: #ad2c2c;
        color: white;
        padding: 0.5rem 1rem;
        font-weight: 600;
        text-transform: uppercase;
    }

    &:nth-of-type(2) {
        flex: 3 1 50%;
        padding: 0.5rem 2rem;
        font-weight: 600;
    }
`;

export const address = css`
    text-align: center;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.4rem;
    font-style: normal;
`;

export const tel = css`
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    text-align: center;
    font-weight: 500;

    a {
        color: #388e3c;
        text-decoration: none;
        font-size: 1.4rem;
        font-weight: 700;
        letter-spacing: -0.05rem;

        &:hover {
            text-decoration: underline;
        }
    }
`;
