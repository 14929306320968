import { css } from '@emotion/react';

export const warning = css`
    margin-bottom: 2rem;
`;

export const content = css`
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
`;

export const cartItemWrapper = css`
    margin-bottom: 2rem;
`;

export const cartTotals = css`
    flex: 0 0 auto;
    margin-top: auto;
`;

export const fees = css`
    border-bottom: 1px solid #ddd;
    display: block;
    margin-bottom: 0.5rem;
`;

export const lineItem = (size: string) => css`
    display: flex;
    justify-content: space-between;
    color: #${size === 'sm' ? 555 : '2d2d2d'};
    font-weight: ${size === 'sm' ? 400 : 600};
    font-size: ${size === 'sm' ? 1.2 : 1.4}rem;
    margin-bottom: 0.5rem;

    span:nth-of-type(1) {
        font-size: 1rem;
    }
`;

export const promo = css`
    font-size: 0.8rem;
    color: #777;
`;

export const discountLineItem = css`
    color: #388e3c;
`;
