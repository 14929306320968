import React from 'react';
import {
    useStaticQuery,
    // Link,
    graphql,
} from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { ProductCategoriesQuery } from '@lib/types';

import * as s from './styles';

const Products = () => {
    const data = useStaticQuery<ProductCategoriesQuery>(graphql`
        {
            allMarkdownRemark(
                filter: {
                    fileAbsolutePath: { regex: "//content/products/" }
                    frontmatter: { featured: { absolutePath: { ne: null } } }
                }
                sort: { fields: [frontmatter___title], order: ASC }
            ) {
                nodes {
                    frontmatter {
                        title
                        featured {
                            name
                            childImageSharp {
                                gatsbyImageData(
                                    layout: CONSTRAINED
                                    width: 600
                                    height: 375
                                    placeholder: BLURRED
                                    transformOptions: { cropFocus: CENTER }
                                )
                            }
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    `);

    return (
        <div css={s.products}>
            <div css={s.container}>
                <ul css={s.list}>
                    {data.allMarkdownRemark.nodes.map(
                        ({
                            frontmatter,
                            //  fields
                        }) => (
                            <li css={s.item} key={frontmatter.title}>
                                {/* <Link to={`/products/${fields.slug}`}> */}
                                <GatsbyImage
                                    css={s.itemImage}
                                    image={frontmatter.featured.childImageSharp.gatsbyImageData}
                                    alt={frontmatter.featured.name.replace(/-/g, ' ')}
                                />
                                <h4 css={s.itemTitle}>{frontmatter.title}</h4>
                                {/* </Link> */}
                            </li>
                        )
                    )}
                </ul>
            </div>
        </div>
    );
};

export default Products;
