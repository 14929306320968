import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import { formatPhone, cleanPhone } from '@lib';
import { MerchantLocation } from '@lib/types';

import * as s from './styles';

type Props = {
    location: MerchantLocation;
};

const Location: React.FC<Props> = ({ location }) => (
    <div>
        <h2 css={s.title} className={!location.isOpen ? 'closed' : ''}>
            {location.city}
        </h2>
        <div css={s.map}>
            <iframe title="Store directions" src={location.map} frameBorder="0" allowFullScreen />
        </div>
        <address css={s.address}>
            {location.address1}
            <br />
            {location.city}, {location.state} {location.zip}
        </address>
        <div css={s.tel}>
            <p>
                Have a question? Give us a call at <br />
                <OutboundLink href={`tel:+1${cleanPhone(location.phoneNumber)}`}>
                    {formatPhone(location.phoneNumber)}
                </OutboundLink>
            </p>
        </div>
        <div css={s.table}>
            {location.schedule.map((day) => (
                <div
                    css={s.row}
                    className={`${day.today ? 'today' : ''} ${day.closed ? 'closed' : ''}`}
                    key={day.weekday}
                >
                    <div css={s.column}>{day.weekday}</div>
                    <div css={s.column}>{day.hours}</div>
                </div>
            ))}
        </div>
    </div>
);

export default Location;
