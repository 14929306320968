import { css } from '@emotion/react';

export const checkbox = (color) => css`
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid ${color};
    border-radius: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    & > div {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translate(0.2rem, -0.4rem) scale(1.6);
    }

    svg {
        width: 100%;
        fill: ${color};
        position: relative;
    }

    input {
        opacity: 0;
        position: absolute;
        z-index: -1000;
    }
`;
