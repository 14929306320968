import { css } from '@emotion/react';

export const products = css`
    position: relative;

    &::before,
    &::after {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        left: 0;
        width: 100%;
        height: 90%;
        z-index: -1;
        transform: translateY(-50%) rotate(3deg);
        width: 110%;
        left: -5%;

        @media (min-width: 41rem) {
            height: 75%;
            transform: translateY(-50%) rotate(5deg);
        }

        @media (min-width: 53.75rem) {
            height: 70%;
            transform: translateY(-50%) rotate(3deg);
        }
    }

    &::before {
        background: url('https://www.colourbox.com/preview/8289755-black-and-white-seamless-texture-tribal.jpg');
        background-size: 24rem;
        opacity: 0.03;
    }

    &:after {
        background: #ffb300;
        z-index: -2;
    }
`;

export const container = css`
    max-width: 77rem;
    padding: 0 1rem;
    margin: auto;
`;

export const list = css`
    max-width: 77rem;
    margin: auto;
    list-style: none;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 3% 1rem;

    @supports (display: grid) {
        display: grid;
        grid-gap: 1rem 1rem;
        padding: 5rem 0;
        grid-template-columns: repeat(2, 1fr);

        @media (min-width: 41rem) {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 0.5rem;
        }

        @media (min-width: 53.75rem) {
            grid-template-columns: repeat(4, 1fr);
        }
    }
`;

export const item = css`
    position: relative;
    border-radius: 0.3rem;
    overflow: hidden;
    display: block;
    flex: 1 1 40%;
    line-height: 1;
    margin: 0.5% 0.5rem;
    text-align: center;
    padding-bottom: 1rem;

    @media (min-width: 41rem) {
        flex: 1 1 22%;
    }

    @media (min-width: 53.75rem) {
        padding-bottom: 0;
    }

    @supports (display: grid) {
        margin: 0;

        grid-column: span 1;
        grid-row: span 1;

        @media (min-width: 53.75rem) {
            &:nth-of-type(1),
            &:nth-of-type(4) {
                grid-row-start: 3;
            }

            &:nth-of-type(3),
            &:nth-of-type(2) {
                grid-row-start: 4;
            }

            &:nth-of-type(8) {
                grid-column: 1 / span 2;
                grid-row: 1 / span 2;

                h4 {
                    min-width: 35%;
                }
            }

            &:nth-of-type(7) {
                grid-column: 3 / span 2;
                grid-row: 2 / span 2;

                h4 {
                    min-width: 35%;
                }
            }

            &:nth-of-type(5) {
                grid-column: 1 / span 2;
                grid-row: 4 / span 2;

                h4 {
                    min-width: 35%;
                }
            }

            &:nth-of-type(6) {
                grid-column: 3 / span 2;
                grid-row: 5 / span 2;

                h4 {
                    min-width: 35%;
                }
            }
        }
    }
`;

export const itemImage = css`
    border-radius: 0.5rem;
`;

export const itemTitle = css`
    padding: 0.5rem 1rem;
    text-align: center;
    color: white;
    font-size: 1rem;
    white-space: nowrap;
    background: #2d2d2d;
    display: block;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0.3rem;
    position: absolute;
    z-index: 2;
    min-width: 70%;

    @media (min-width: 53.75rem) {
        bottom: 0.3rem;
        right: 0.3rem;
        left: auto;
        transform: none;
    }
`;
