import React from 'react';

import { useCart } from '@hooks';
import CartItem from '@components/CartItem';

import * as s from './styles';

type Props = {
    onLinkClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    fullCart?: boolean;
};

const Cart: React.FC<Props> = ({ onLinkClick, fullCart = true }) => {
    const {
        quantity,
        items,
        totalPrice,
        totalTax,
        totalShipping,
        totalDiscount,
        total,
        add,
        remove,
        isDelivery,
        isInState,
        activePromotions,
    } = useCart();

    return (
        <div css={s.content}>
            {quantity === 0 && <p css={s.warning}>Woah. Your shopping cart is empty.</p>}
            {items.map((item) => (
                <div css={s.cartItemWrapper} key={item.code}>
                    <CartItem
                        item={item}
                        onChange={(value) => (value > item.quantity ? add(item) : remove(item))}
                        onLinkClick={onLinkClick}
                    />
                </div>
            ))}
            <div css={s.cartTotals}>
                <div css={s.lineItem('md')}>
                    <span>Order Total</span> <span>${((totalPrice + totalDiscount) / 100).toFixed(2)}</span>
                </div>
                <div css={s.fees}>
                    {activePromotions.length > 0 && (
                        <div css={[s.lineItem('sm'), s.discountLineItem]}>
                            <span>
                                Discount
                                {activePromotions.map(({ code }) => (
                                    <div css={s.promo} key={code}>
                                        {code}
                                    </div>
                                ))}
                            </span>
                            <span>- ${(totalDiscount / 100).toFixed(2)}</span>
                        </div>
                    )}
                    {isInState && (
                        <div css={s.lineItem('sm')}>
                            <span>Tax</span> <span>${(totalTax / 100).toFixed(2)}</span>
                        </div>
                    )}
                    {fullCart && (
                        <div css={s.lineItem('sm')}>
                            <span>{isDelivery ? 'Delivery' : 'Shipping'}</span>
                            <span>{isDelivery ? 'FREE' : `$${(totalShipping / 100).toFixed(2)}`}</span>
                        </div>
                    )}
                </div>
                <div css={s.lineItem('md')}>
                    <span>Cart Total</span>
                    <span>${fullCart ? (total / 100).toFixed(2) : ((total - totalShipping) / 100).toFixed(2)}</span>
                </div>
            </div>
        </div>
    );
};

export default Cart;
