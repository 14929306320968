import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { ItemGroupThumbnail } from '@lib/types';
import Price from '@components/Price';
import Hoverlay from '@components/Hoverlay';

import * as s from './styles';

type Props = {
    itemNode: ItemGroupThumbnail;
};

const ProductThumbnail: React.FC<Props> = ({ itemNode }) => {
    const { slug, gatsbyImageData, name, categories, price } = useMemo(() => {
        const item = (
            itemNode.unifiedItems.find((unifiedItem) => unifiedItem.rootCloverItem.imageFiles !== null) ??
            itemNode.unifiedItems[0]
        ).rootCloverItem;

        return {
            slug: itemNode.slug,
            name: itemNode.name,
            gatsbyImageData: item.imageFiles[0].childImageSharp.gatsbyImageData,
            categories: item.categories,
            price: item.price,
        };
    }, [itemNode.name]);
    const inStoreOnly = Boolean(categories.find((category) => category.name === 'Website No Sale'));

    return (
        <Link key={slug} css={s.itemListing} to={`/products/${slug}`}>
            {inStoreOnly && <div css={s.callout}>In Store Only</div>}
            <Hoverlay label="See Details">
                <GatsbyImage image={gatsbyImageData} alt={name} />
            </Hoverlay>
            <h3 css={s.itemName}>{name}</h3>
            <div css={s.categoryTags}>
                {categories
                    .filter((category) => category.markdownFile)
                    .map((category) => (
                        <div key={category.markdownFile.childMarkdownRemark.frontmatter.title}>
                            {category.markdownFile.childMarkdownRemark.frontmatter.title}
                        </div>
                    ))}
            </div>
            <p css={s.priceWrapper}>
                <Price value={price} />
            </p>
        </Link>
    );
};

export default ProductThumbnail;
