import { css } from '@emotion/react';

export const pagination = css`
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

export const paginationLink = css`
    flex: 0 0 auto;
    margin: 0.1rem;

    a,
    span {
        display: inline-block;
        padding: 0.5rem 1rem;
        border: 1px solid #eeeeee;
        border-radius: 0.2rem;
        text-decoration: none;
        color: #ffb300;
        font-weight: 700;
    }

    span {
        color: #cccccc;
    }
`;

export const paginationCurrent = css`
    font-weight: 900;
`;
