import { css } from '@emotion/react';

export const wrapper = css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
`;

export const overlay = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
`;

export const container = css`
    position: absolute;
    top: 50%;
    left: 50%;
    background: transparent;
    width: 100%;
    max-width: 28rem;
    z-index: 1;
`;

export const closeBtn = css`
    position: absolute;
    top: 0.5rem;
    right: 0.7rem;
    width: 1rem;
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s;

    &:hover {
        opacity: 0.6;
    }

    &:focus {
        transition: none;
        opacity: 1;
    }
`;
