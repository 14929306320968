import React from 'react';
import { motion } from 'framer-motion';

import Icon from '@components/Icon';

import * as s from './styles';

type Props = {
    id: string;
    name: string;
    checked: boolean;
    form?: string;
    color?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

const Checkbox: React.FC<Props> = ({ id, name, onChange, checked, form, color = '#ffb300' }) => (
    <div css={s.checkbox(color)}>
        <input type="checkbox" id={id} name={name} form={form} onChange={onChange} checked={checked} />
        <motion.div
            animate={{
                opacity: checked ? 1 : 0,
                scale: checked ? 1.6 : 0,
                x: '0.2rem',
                y: '-0.4rem',
            }}
        >
            <Icon name="checkmark" />
        </motion.div>
    </div>
);

export default Checkbox;
