import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Item } from '@lib/types';
import Counter from '@components/Counter';

import * as s from './styles';

type Props = {
    item: Item;
    onChange: (value: number) => void;
    onLinkClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const CartItem: React.FC<Props> = ({ item, onChange, onLinkClick }) => {
    const total = ((item.price * item.quantity) / 100).toFixed(2);

    return (
        <div css={s.cartItem}>
            <div css={s.imageWrapper}>
                <Link to={`/products/${item.slug}`} onClick={onLinkClick}>
                    <GatsbyImage css={s.image} image={item.gatsbyImageData} alt={item.name} />
                </Link>
            </div>
            <div css={s.content}>
                <h4>
                    <Link css={s.title} to={`/products/${item.slug}`} onClick={onLinkClick}>
                        {item.name}
                    </Link>
                </h4>
                <div css={s.counter}>
                    <Counter size="sm" value={item.quantity} onChange={onChange} />
                </div>
            </div>
            <div css={s.col3}>
                <div css={s.itemTotal}>${total}</div>
                <div>
                    (${(item.price / 100).toFixed(2)} x {item.quantity})
                </div>
            </div>
        </div>
    );
};

export default CartItem;
