import React from 'react';

import * as s from './styles';

type Props = {
    label: string;
    color?: string;
};

const Hoverlay: React.FC<Props> = ({ children, label, color = '#ffb300' }) => (
    <div css={s.wrapper(color)}>
        <div>{children}</div>
        <div>{label}</div>
    </div>
);

export default Hoverlay;
