import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

type Props = {
    title: string;
    description?: string;
    lang?: string;
    keywords?: string[];
    meta?: Array<{
        name: string;
        content: string;
    }>;
};

const SEO: React.FC<Props> = ({ description = '', lang = 'en', meta = [], keywords = [], title }) => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `
    );

    const metaDescription = description || site.siteMetadata.description;

    return (
        <Helmet>
            <html lang={lang} />
            <title>{`${title} | ${site.siteMetadata.title}`}</title>
            <meta name="description" content={metaDescription} />
            <meta name="og:title" content={title} />
            <meta name="og:description" content={metaDescription} />
            <meta name="og:type" content="website" />
            <meta name="keywords" content={keywords.join(', ')} />
        </Helmet>
    );
};

export default SEO;
