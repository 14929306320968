import { css } from '@emotion/react';

export const alert = css`
    background: #ffb300;
    padding: 1rem 2rem;
    border-radius: 0.2rem;
    color: white;
    font-weight: 600;
`;

export const container = css`
    max-width: 38rem;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    & > :first-of-type {
        flex: 0 0 2rem;
        fill: white;
        margin-right: 1.5rem;
    }

    & > :last-of-type {
        flex: 1 1 50%;
    }

    a {
        color: white;
    }
`;
