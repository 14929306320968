import { css } from '@emotion/react';

export const label = css`
    display: block;
    position: relative;

    input {
        display: block;
        width: 100%;
        font-family: Rubik, Helvetica, Arial, sans-serif;
        border-radius: 0.3rem;
        border: 2px solid #999;
        padding: 0.5rem 1rem;
        transition: border 0.3s;

        &:focus {
            outline: none;
            border-color: #388e3c;
        }
    }
`;

export const buttonWrapper = css`
    margin-top: 0.3rem;
    width: 9rem;
    margin-left: auto;
`;
