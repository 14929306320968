import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import * as s from './styles';

type Props = {
    size?: 'sm' | 'md';
    value: number;
    onChange: (value: number) => void;
};

const Counter: React.FC<Props> = ({ size = 'md', value, onChange }) => (
    <div css={s.counter(size)}>
        <button type="button" aria-label="minus" onClick={() => onChange(value - 1)} />
        <AnimatePresence initial={false}>
            <motion.div
                css={s.countWrapper}
                key={value}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
            >
                {value}
            </motion.div>
        </AnimatePresence>
        <button type="button" aria-label="plus" onClick={() => onChange(value + 1)} />
    </div>
);

export default Counter;
