import React from 'react';
import { css } from '@emotion/react';

const wrapper = css`
    max-width: 57rem;
    padding: 0 1rem;
    margin: 6rem auto;
    position: relative;
`;

const Wrapper: React.FC = ({ children }) => <div css={wrapper}>{children}</div>;

export default Wrapper;
