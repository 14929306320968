import { css, keyframes } from '@emotion/react';

export const spin = keyframes`
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
`;

export const spinner = css`
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        content: '';
        box-sizing: border-box;
        display: block;
        width: 1.2rem;
        height: 1.2rem;
        border: 4px solid rgba(255, 255, 255, 0.4);
        border-top-color: rgba(255, 255, 255, 1);
        border-radius: 1rem;
        animation: ${spin} 0.7s linear infinite;
    }
`;

export const wrapper = css`
    overflow: hidden;
    position: relative;
    text-align: right;
`;

export const buttonWrapper = css`
    display: inline-block;
    width: 16rem;
`;

export const locationsWrapper = css`
    flex: 0 0 auto;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 110%;
    left: 0;
    width: 100%;
    height: 100%;

    &::before {
        content: '';
        display: block;
        flex: 1 1 100%;
    }
`;

export const item = css`
    flex: 0 0 auto;
    margin-left: 0.5rem;
    width: auto;
    text-decoration: none;
`;
