import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import fetch from 'unfetch';

import Button from '@components/Button';

import * as s from './styles';

const STATUS = {
    LOADING: 'LOADING',
    DEFAULT: 'DEFAULT',
    COMPLETE: 'COMPLETE',
    ERROR: 'ERROR',
};

type Props = {
    code: string;
};

const AvailabilityButton: React.FC<Props> = ({ code }) => {
    const [status, setStatus] = useState(STATUS.DEFAULT);
    const [locations, setLocations] = useState([]);

    const buttonMessage = {
        [STATUS.DEFAULT]: 'Check In-Store Availability',
        [STATUS.LOADING]: <div css={s.spinner} />,
        [STATUS.COMPLETE]: "Sorry, we're out of stock",
        [STATUS.ERROR]: 'Error searching inventory',
    }[status];

    const handleClick = async () => {
        if (status === STATUS.DEFAULT) {
            setStatus(STATUS.LOADING);
            try {
                const response = await fetch(`${process.env.API_GET_INVENTORY}/${code}`, {
                    method: 'GET',
                });

                setLocations(response.status === 200 ? await response.json() : []);
                setStatus(STATUS.COMPLETE);
            } catch {
                setStatus(STATUS.ERROR);
            }
        }
    };

    useEffect(() => {
        if (status !== STATUS.DEFAULT) {
            setStatus(STATUS.DEFAULT);
        }
    }, [code]);

    return (
        <div css={s.wrapper}>
            <motion.div animate={{ y: status === STATUS.COMPLETE && locations.length > 0 ? '110%' : 0 }}>
                <div css={s.buttonWrapper}>
                    <Button
                        id={status}
                        size="sm"
                        color={status === STATUS.COMPLETE && locations.length === 0 ? '#ad2c2c' : '#ffb300'}
                        onClick={handleClick}
                    >
                        {buttonMessage}
                    </Button>
                </div>
                <div css={s.locationsWrapper}>
                    {locations.map(({ name, quantity }) => (
                        <Link css={s.item} to="/locations">
                            <Button size="sm">
                                {name} ({quantity})
                            </Button>
                        </Link>
                    ))}
                </div>
            </motion.div>
        </div>
    );
};

export default AvailabilityButton;
