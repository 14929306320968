import React from 'react';
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, Link, graphql } from 'gatsby';

import { BrandImagesQuery } from '@lib/types';

import * as s from './styles';

const Main = () => {
    const data = useStaticQuery<BrandImagesQuery>(graphql`
        {
            allFile(filter: { sourceInstanceName: { eq: "images" }, relativePath: { regex: "/brands//" } }, limit: 12) {
                nodes {
                    name
                    childImageSharp {
                        gatsbyImageData(
                            layout: CONSTRAINED
                            width: 150
                            placeholder: BLURRED
                            transformOptions: { fit: CONTAIN }
                        )
                    }
                }
            }
        }
    `);

    return (
        <div css={s.main}>
            <div css={s.container}>
                <h1 css={s.title}>We Can Help With That</h1>
                <div css={s.content}>
                    <StaticImage
                        css={s.image}
                        src="../../content/images/employee.jpg"
                        alt="Employee smiling at the register"
                        width={550}
                        layout="constrained"
                        placeholder="blurred"
                    />
                    <p css={s.text}>
                        Come on in and relax. Welcome to 410 Smoke Shop, Maryland&apos;s finest head shop. Looking for
                        your next piece or accessory? We got you covered. And don&apos;t sweat, we have the brands you
                        know and love from all over the country and more. Our team is always ready to assist you so take
                        a look around, stop by one of our locations, or <Link to="/contact">contact us</Link> with any
                        questions or feedback.
                    </p>
                </div>
                <ul css={s.brands}>
                    {data.allFile.nodes.map(({ childImageSharp, name }) => (
                        <li css={s.brand} key={name}>
                            <GatsbyImage
                                css={s.brandImage}
                                image={childImageSharp.gatsbyImageData}
                                alt={`${name.replace(/-/g, ' ')} logo`}
                                objectFit="contain"
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Main;
