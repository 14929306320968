import React, { useEffect, useState } from 'react';

type Props = {
    fallback?: React.ElementType;
};

const NoSSR: React.FC<Props> = ({ children, fallback = null }) => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    return <>{isMounted ? children : fallback}</>;
};

export default NoSSR;
