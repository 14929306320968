import { css } from '@emotion/react';

export const counter = (size) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${size === 'md' ? 1.2 : 0.9}rem;
    position: relative;
    user-select: none;

    button {
        text-align: center;
        width: 1.6em;
        height: 1.6em;
        background: transparent;
        border: 0.15em solid #777;
        border-radius: 0.35em;
        color: #777;
        font-weight: 900;
        position: relative;
        cursor: pointer;
        transform: translateY(0);
        transition: all 0.2s;

        &:focus {
            outline: none;
        }

        &:active {
            transform: translateY(0.2rem);
        }

        &:hover {
            border-color: #555;
            color: #555;
        }

        &:first-of-type::after {
            content: '-';
            display: block;
            position: absolute;
            top: calc(50% - 0.1em);
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:last-of-type::after {
            content: '+';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    div {
        width: 2em;
        text-align: center;
        color: #777;
        font-weight: 500;
        font-size: 1.2em;
    }
`;

export const countWrapper = css`
    &:not(:first-of-type) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;
