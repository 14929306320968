import { css } from '@emotion/react';

export const main = css`
    margin-top: 4rem;

    @media (min-width: 44rem) {
        margin-top: 6rem;
    }
`;

export const container = css`
    max-width: 77rem;
    padding: 0 1rem;
    margin: auto;
`;

export const title = css`
    color: #ffb300;
    font-weight: 900;
    font-size: 13vw;
    margin-bottom: 4rem;
    text-align: center;
    font-weight: 900;
    position: relative;

    @media (min-width: 26.25rem) {
        font-size: 7.5vw;
    }

    @media (min-width: 56.25rem) {
        font-size: 4rem;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: -0.5em;
        left: 50%;
        transform: translateX(-50%);
        width: 1.2em;
        height: 0.3em;
        background-image: radial-gradient(circle, #ffb300 35%, transparent 0);
        background-size: 0.3em 0.3em;
        background-repeat: repeat-x;
        background-position: 0 0;
    }
`;

export const content = css`
    max-width: 32rem;
    margin: auto;

    @media (min-width: 50rem) {
        display: flex;
        align-items: center;
        max-width: none;
    }
`;

export const image = css`
    flex: 1 1 50%;
    align-self: flex-start;
    margin-right: 3%;
    margin-bottom: 2rem;
`;

export const text = css`
    flex: 1 1 50%;
    margin-left: 3%;
    font-size: 1rem;
    line-height: 2;
    font-weight: 500;

    @media (min-width: 50rem) {
        font-size: 1.25rem;
    }
`;

export const brands = css`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 4rem 0;
`;

export const brand = css`
    flex: 0 0 12%;
    min-width: 5rem;
    margin: 1rem 4% 1rem 0;

    &:nth-of-type(6n) {
        margin-right: 0;
    }
`;

export const brandImage = css`
    max-height: 100px;
`;
