import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';

import Icon from '@components/Icon';

import * as s from './styles';

type Props = {
    open: boolean;
    onClose: (event: React.MouseEvent | KeyboardEvent) => void;
    blur?: boolean;
};

const Modal: React.FC<Props> = ({ children, open, onClose, blur }) => {
    useEffect(() => {
        if (open) {
            const handleEscape = (event: KeyboardEvent) => {
                if (event.code === 'Escape' || event.which === 27 || event.keyCode === 27) {
                    onClose(event);
                }
            };

            window.addEventListener('keyup', handleEscape);

            return () => window.removeEventListener('keyup', handleEscape);
        }

        return undefined;
    }, [open]);

    return typeof document !== 'undefined'
        ? createPortal(
              <AnimatePresence>
                  {open && (
                      <motion.div
                          css={s.wrapper}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                      >
                          <motion.div
                              css={s.container}
                              initial={{ x: '-50%', y: '-80%' }}
                              animate={{ x: '-50%', y: '-50%' }}
                              exit={{ x: '-50%', y: '-20%' }}
                          >
                              <button css={s.closeBtn} type="button" onClick={onClose}>
                                  <Icon name="times" />
                              </button>
                              {children}
                          </motion.div>
                          <motion.div
                              css={s.overlay}
                              onClick={onClose}
                              style={{ backdropFilter: blur ? 'blur(20px)' : undefined }}
                          />
                      </motion.div>
                  )}
              </AnimatePresence>,
              document.body
          )
        : null;
};

export default Modal;
