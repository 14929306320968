import { css } from '@emotion/react';

export const wrapper = (color) => css`
    border-radius: 0.3rem;
    overflow: hidden;
    position: relative;

    &:hover {
        &::after {
            opacity: 0.5;
        }
        & > div:first-of-type {
            transform: rotate(2deg) scale(1.1);
        }
        & > div:last-of-type {
            opacity: 1;
        }
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${color};
        opacity: 0;
        transition: opacity 0.3s;
    }

    & > div:first-of-type {
        transform: rotate(0) scale(1);
        transition: transform 0.3s;
    }

    & > div:last-of-type {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-weight: 600;
        border: 3px solid white;
        border-radius: 0.3rem;
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
        z-index: 2;
        opacity: 0;
        transition: opacity 0.3s;
        white-space: nowrap;
    }
`;
