import { css } from '@emotion/react';

export const circle = css`
    position: relative;
    width: 100%;

    &::before {
        content: '';
        display: block;
        border-radius: 50%;
        background: #ad2c2c;
        padding-top: 100%;
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding-top: 100%;
        border-radius: 50%;
        z-index: 1;
        border: 6px solid #ffb300;
        left: calc(50% - 0.5rem);
        top: calc(50% - 0.5rem);
    }
`;

export const content = css`
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    z-index: 2;
`;

export const h2 = css`
    font-weight: 900;
    font-size: 0.9rem;
    margin-bottom: 0.3rem;
    text-transform: uppercase;
    color: #ffb300;
`;

export const address = css`
    display: block;
    color: white;
    text-decoration: none;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.1;
    letter-spacing: -0.05rem;

    &:hover {
        text-decoration: underline;
    }

    address {
        font-style: normal;
    }
`;

export const tel = css`
    display: block;
    color: #ffb300;
    text-decoration: none;
    font-weight: 600;
    margin-top: 0.5rem;

    &:hover {
        text-decoration: underline;
    }
`;
