import React from 'react';
import { Link } from 'gatsby';

import * as s from './styles';

type Props = {
    baseUrl: string;
    currentPage: number;
    totalPages: number;
};

const Pagination: React.FC<Props> = ({ baseUrl, currentPage, totalPages }) => (
    <ul css={s.pagination}>
        <li css={s.paginationLink}>
            {currentPage === 1 ? (
                <span>Previous</span>
            ) : (
                <Link to={currentPage === 2 ? baseUrl : `${baseUrl}/${currentPage - 1}`}>Previous</Link>
            )}
        </li>
        {Array.from({ length: totalPages })
            .map((_, i) => i)
            .map((i) => (
                <li key={i} css={s.paginationLink}>
                    {i + 1 === currentPage ? (
                        <span css={s.paginationCurrent}>{i + 1}</span>
                    ) : (
                        <Link to={!i ? baseUrl : `${baseUrl}/${i + 1}`}>{i + 1}</Link>
                    )}
                </li>
            ))}
        <li css={s.paginationLink}>
            {currentPage === totalPages ? <span>Next</span> : <Link to={`${baseUrl}/${currentPage + 1}`}>Next</Link>}
        </li>
    </ul>
);

export default Pagination;
